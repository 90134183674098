import React, { useState, useEffect } from "react";

const AutoRedirectImage = () => {
  const [currentImage, setCurrentImage] = useState(0); // State to track the current displayed image
  const images = ["./images/bg/个人投资2.png", "./images/bg/服务2.png"]; // insert before & after image here

  useEffect(() => {
    // Set an interval to change the image every 3 seconds
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length); // Change image
    }, 3000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div>
      {/* Render the current image */}
      <img
        src={images[currentImage]}
        alt={`Slide ${currentImage}`}
        style={{ width: "100%", height: "auto" }}
      />
    </div>
  );
};

export default AutoRedirectImage;
