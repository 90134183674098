import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const WhyChoose = () => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0); // Track current set of visible cards
  const reasons = [
    { icon: "/images/icons/icon1.jpg", title: t("546"), description: t("547") },
    { icon: "/images/icons/icon2.jpg", title: t("548"), description: t("549") },
    { icon: "/images/icons/icon3.jpg", title: t("550"), description: t("551") },
    { icon: "/images/icons/icon4.jpg", title: t("552"), description: t("553") },
    { icon: "/images/icons/icon5.jpg", title: t("554"), description: t("555") },
  ];
  const visibleCards = 3; // Number of cards to show at a time

  // Function to handle previous button click
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? reasons.length - visibleCards : prevIndex - 1
    );
  };

  // Function to handle next button click
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex >= reasons.length - visibleCards ? 0 : prevIndex + 1
    );
  };

  return (
    <div
      style={{
        position: "relative",
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "40px 20px",
        overflow: "hidden", // Hide cards outside the view
      }}
    >
      {/* Card List */}
      <div
        style={{
          display: "flex",
          transform: `translateX(-${currentIndex * (100 / visibleCards)}%)`,
          transition: "transform 0.5s ease",
        }}
      >
        {reasons.map((reason, index) => (
          <Card
            key={index}
            icon={reason.icon}
            title={reason.title}
            description={reason.description}
          />
        ))}
      </div>

      {/* Navigation Buttons */}
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          right: "20px",
          display: "flex",
          gap: "10px",
        }}
      >
        <button onClick={handlePrevious} style={buttonStyle}>
          &lt;
        </button>
        <button onClick={handleNext} style={buttonStyle}>
          &gt;
        </button>
      </div>
    </div>
  );
};

const buttonStyle = {
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  backgroundColor: "#e0e0e0",
  border: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

// Card Component for individual reasons
const Card = ({ icon, title, description }) => {
  return (
    <div
      style={{
        flex: "1 0 calc(33.33% - 20px)", // Show 3 cards at a time
        maxWidth: "calc(33.33% - 20px)", // Show 3 cards at a time
        margin: "10px",
        backgroundColor: "#fff",
        borderRadius: "10px",
        padding: "20px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start", // Align items to the start (left)
        textAlign: "left", // Align text to the left
      }}
    >
      {/* Icon */}
      <img
        src={icon}
        alt={title}
        style={{
          width: "80px", // Adjust icon size as needed
          height: "80px",
          objectFit: "contain",
          marginBottom: "20px", // Space between icon and title
        }}
      />
      {/* Title */}
      <h3
        style={{
          fontSize: "18px", // Adjust font size
          fontWeight: "600",
          margin: "0 0 10px 0", // Space between title and description
        }}
      >
        {title}
      </h3>
      {/* Description */}
      <p style={{ fontSize: "14px", color: "#666", margin: "0" }}>
        {description}
      </p>
    </div>
  );
};

export default WhyChoose;
