import React from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import HeaderTopNews from "../common/header/HeaderTopNews";
import HeaderTwo from "../common/header/HeaderTwo";
import FooterTwo from "../common/footer/FooterTwo";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import TabOne from "../elements/tab/TabOne";
import AboutThree from "../elements/about/AboutThree";
import PricingThree from "../elements/pricing/PricingThree";
import BlogList from "../components/blog/itemProp/BlogList";
import Copyright from "../common/footer/Copyright";
import BlogClassicData from "../data/blog/BlogList.json";
var BlogListData = BlogClassicData.slice(0, 3);

const Business = () => {
  return (
    <>
      <SEO title="Business" />
      <main className="page-wrapper">
        {/* <HeaderTopNews /> */}
        <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-3 height-850">
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                <div className="inner text-start">
                  <h1 className="theme-gradient">
                    Development <br />
                    Management
                  </h1>
                  <p className="description">
                    We are a data-driven real estate development service
                    company, specializing in leveraging the latest data
                    analytics technologies and industry expertise to manage
                    pre-construction stages of real estate development.{" "}
                  </p>
                  <div className="button-group mt--30 mt_sm--20">
                    <a
                      className="btn-default btn-medium btn-icon"
                      href="\contact"
                    >
                      Contact us{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="order-1 order-lg-2 col-lg-5">
                <div className="thumbnail">
                  <img
                    src="./images/about/project.jpg"
                    alt="Cyberate Investment Project Management"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />
        <div className="rwt-timeline-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Services Process."
                  title="Working Process."
                  description="Our approach encompasses selecting development projects through big data, guiding land purchase negotiations with data-driven insights, and using algorithmic analysis to inform market-oriented planning. Our process extends to obtaining development approval and managing sales."
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 offset-lg-1 mt--50">
                <TimelineTwo classVar="dark-line" />
              </div>
            </div>
          </div>
        </div>

        <Separator />

        {/* Start Elements Area  */}
        {/* <div className="rwt-tab-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Companies About."
                                    title="What About Our Companies."
                                    description="We help our clients succeed by creating brand identities, <br /> digital experiences, and print materials."
                                />
                            </div>
                        </div>
                        <TabOne />
                    </div>
                </div> */}
        {/* End Elements Area  */}

        {/* <Separator /> */}
        {/* <AboutThree /> */}

        {/* <Separator /> */}
        {/* Start Elements Area  */}
        {/* <div className="rwt-pricing-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--35">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Our Pricing Plan"
                                    title="Business Pricing Plan."
                                    description="We help our clients succeed by creating brand identities, <br /> digital experiences, and print materials."
                                />
                            </div>
                        </div>
                        <PricingThree />
                    </div>
                </div> */}
        {/* End Elements Area  */}

        {/* <Separator /> */}
        <div className="blog-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Our main business activities"
                  title="Stock, Real estate and project investment service."
                  description="We provide company and finance service for <br /> your startup investment."
                />
              </div>
            </div>
            <div className="row row--15">
              {BlogListData.map((item) => (
                <div key={item.id} className="col-lg-4 col-md-6 col-12 mt--30">
                  <BlogList StyleVar="box-card-style-default" data={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};
export default Business;
