import React, { useEffect } from "react";

const Copyright = () => {
  // Add Google Tag script
  useEffect(() => {
    // Add the Google Tag (gtag.js) script to the <head>
    const script1 = document.createElement("script");
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=AW-16672072231";
    document.head.appendChild(script1);

    // Add the Google Tag initialization script
    const script2 = document.createElement("script");
    script2.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16672072231');
        `;
    document.head.appendChild(script2);

    // Cleanup the script when component unmounts
    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []);
  return (
    <div className="copyright-area copyright-style-one">
      <div className="container">
        <div className="row align-items-center">
          {/* <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                        <div className="copyright-left">
                            <ul className="ft-menu link-hover">
                                <li><a href="#">Privacy Policy</a></li>
                                <li><a href="#">Terms And Condition</a></li>
                                <li><a href="/contact">Contact Us</a></li>
                            </ul>
                        </div>
                    </div> */}
          <div className="col-lg-6 col-md-4 col-sm-12 col-12">
            <div className="copyright-left text-center text-md-end">
              <p className="copyright-text" style={{ textAlign: "left" }}>
                © Cyberate Investments {new Date().getFullYear()}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-9 col-md-9 col-sm-12 col-12">
            <div className="copyright-left">
              <dr />
              <p style={{ fontSize: 12, paddingTop: 20 }}>
                Cyberate and its employees wish to acknowledge and respect
                Aboriginal peoples as the state's first peoples and nations. We
                recognise their connection to the land, water and culture and
                wish to pay our respects to their Elders past, present and
                emerging.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Copyright;
