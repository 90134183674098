import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const teamData = [
    {
        image: 'Jax',
        name: 'Jax',
        designation: 'General Manager',
        row: 0,
    },
    {
        image: 'Viv',
        name: 'Viv',
        designation: 'Strategy Manager',
        row: 0,
    },
    {
        image: 'Ashley',
        name: 'Ashley',
        designation: 'Product Manager',
        row: 1,
    },
    {
        image: 'Patrick',
        name: 'Junzhe Diao',
        designation: 'Technical Manager',
        row: 1,
    },
    {
        image: 'Lin',
        name: 'Lin',
        designation: 'Marketing Manager',
        row: 1,
    },
    {
        image: 'Lorenzo',
        name: 'Xinchao Lin',
        designation: 'Architect',
        row: 2,
    },
    {
        image: 'Angelia',
        name: 'Angelia',
        designation: 'Administration',
        row: 2,
    },
    {
        image: 'Irene',
        name: 'Irene',
        designation: 'Statistician',
        row: 2,
    },
    {
        image: 'Rosy',
        name: 'Rosy',
        designation: 'Accountant',
        row: 2,
    },
    {
        image: 'Jessie',
        name: 'Jessie',
        designation: 'Data Analyst',
        row: 3,
    },
    {
        image: 'Candice',
        name: 'Candice',
        designation: 'Data Analyst',
        row: 3,
    },
    {
        image: 'Jason',
        name: 'Jason',
        designation: 'Software Engineer',
        row: 3,
    },
    {
        image: 'Winston',
        name: 'Winston',
        designation: 'Software Engineer',
        row: 4,
    },
    {
        image: 'Eugenia',
        name: 'Eugenia',
        designation: 'Software Engineer',
        row: 4,
    },
    {
        image: 'Mingjun',
        name: 'Mingjun',
        designation: 'Software Engineer',
        row: 4,
    },
    {
        image: 'yuchen',
        name: 'Yuchen',
        designation: 'Software Engineer',
        row: 4,
    },
]


const TeamThree = ({ column, teamStyle }) => {
    // 首先按行分组团队成员
    const rows = teamData.reduce((acc, data) => {
        if (!acc[data.row]) {
            acc[data.row] = [];
        }
        acc[data.row].push(data);
        return acc;
    }, {});

    const subtitleStyle = {
        opacity: 1,
        visibility: 'visible',
        transform: 'translateY(0px)'
    };

    return (
        <div className="team-container">
            {Object.keys(rows).map((rowKey) => (
                <div className="row row--30 justify-content-center" key={rowKey}>
                    {rows[rowKey].map((data, index) => (
                        <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <div className={`rn-team ${teamStyle}`}>
                                    <div className="inner">
                                        <figure className="thumbnail">
                                            <img src={`./images/team/${data.image}.png`} alt="Cyberate Team" />
                                        </figure>
                                        <figcaption className="content">
                                            <h2 className="title">{data.name}</h2>
                                            <h6 className="subtitle theme-gradient" style={subtitleStyle}>{data.designation}</h6>
                                        </figcaption>
                                    </div>
                                    <br />
                                </div>
                            </ScrollAnimation>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}

export default TeamThree;
