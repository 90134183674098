import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const WeUnderstandYou = () => {
  const { t } = useTranslation();
  const contentData = {
    [t("526")]: t("539"),
    [t("527")]: t("540"),
    [t("528")]: t("541"),
    [t("529")]: t("542"),
    [t("530")]: t("543"),
    [t("531")]: t("544"),
    [t("532")]: t("545"),
  };

  const [selectedSection, setSelectedSection] = useState(null); // Default to no section

  const handleSectionClick = (section) => {
    setSelectedSection(selectedSection === section ? null : section);
  };

  // Inline styles
  const containerStyle = {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "20px",
    display: "flex",
  };

  const accordionMenuStyle = {
    flex: "1",
  };

  const accordionItemStyle = (isActive) => ({
    padding: "15px",
    fontSize: "1.2em",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    marginBottom: "5px",
    borderRadius: "8px",
    backgroundColor: isActive ? "#f0f0f0" : "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  });

  const contentStyle = {
    marginTop: "10px",
    padding: "15px",
    fontSize: "1.2em",
    lineHeight: "1.6",
    borderRadius: "8px",
  };

  const headingStyle = {
    fontSize: "1.8em",
    marginBottom: "10px",
  };

  const paragraphStyle = {
    fontSize: "1.2em",
    lineHeight: "1.6",
  };

  // Update arrow style for rotation
  const arrowStyle = (isActive) => ({
    fontSize: "1.2em",
    transition: "transform 0.3s ease",
    transform: isActive ? "rotate(0deg)" : "rotate(0deg)", // Rotate arrow for active state
    display: "inline-block",
  });

  const arrowSymbol = (isActive) => (isActive ? "∧" : "v"); // Ensure correct arrow symbol

  const imageContainerStyle = {
    flex: "0 0 300px", // Fixed width for the image container
    marginLeft: "20px", // Space between content and image
  };

  const imageStyle = {
    maxWidth: "100%", // Ensure the image fits within its container
    height: "auto", // Maintain aspect ratio
  };

  return (
    <div style={containerStyle}>
      {/* Left Side Accordion */}
      <div style={accordionMenuStyle}>
        {Object.keys(contentData).map((section, index) => (
          <div key={index}>
            <div
              style={accordionItemStyle(selectedSection === section)}
              onClick={() => handleSectionClick(section)}
            >
              {section}
              <span style={arrowStyle(selectedSection === section)}>
                {arrowSymbol(selectedSection === section)}
              </span>
            </div>
            {/* Conditionally render the content for the selected section */}
            {selectedSection === section && (
              <div style={contentStyle}>
                <h2 style={headingStyle}>{section}</h2>
                <p style={paragraphStyle}>{contentData[selectedSection]}</p>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Right Side Image */}
      <div style={imageContainerStyle}>
        <img
          src="./images/bg/个人投资2.png" // Replace with your image path
          alt="Description"
          style={imageStyle}
        />
      </div>
    </div>
  );
};

export default WeUnderstandYou;
