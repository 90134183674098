import React from "react";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import Copyright from "../common/footer/Copyright";
import FooterTwo from "../common/footer/FooterTwo";
import { useTranslation } from "react-i18next";
import "./AboutUs.css";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO title="About Us - Cyberate Investments, our value, our management scale, our people" />
      <main className="page-wrapper">
        <HeaderOne></HeaderOne>
        <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 order-2 order-lg-1 mt_md--40 mt_sm--40">
                <div className="inner text-start">
                  <h1 className="title theme-gradient display-two">
                    {t("38")}
                  </h1>
                  <p className="description">{t("507")}</p>
                </div>
              </div>
              <div className="col-lg-4 order-1 order-lg-2">
                <div className="thumbnail">
                  <img src="./images/bg/服务2.png" alt="Banner Images" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Service and other sections */}
        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};

export default AboutUs;
