import React, { useState } from "react";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import QRCode from "react-qr-code";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import AutoRedirectImage from "../elements/service/AutoRedirectImage";
import WeUnderstandYou from "../elements/service/WeUnderstandYou";
import WhyChoose from "../elements/service/WhyChoose";
import CaseSection from "../elements/service/CaseSection";

const ExtensiveLandOldHouse = () => {
  const { t } = useTranslation();
  const [showQRCode, setShowQRCode] = useState(false);

  const handleButtonClick = () => {
    setShowQRCode(!showQRCode);
  };

  return (
    <>
      <SEO title="Extensive Land Old House Project" />
      <HeaderOne />

      {/* Slider Area */}
      <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
              <div className="inner text-start">
                <h1 className="title theme-gradient display-two">{t("522")}</h1>
                <p className="description">{t("524")}</p>
              </div>
            </div>

            <div className="col-lg-4 order-1 order-lg-2">
              <AutoRedirectImage />
            </div>
          </div>
        </div>
      </div>

      {/* Project Introduction Section */}
      <div className="container">
        <div className="row">
          <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
            <div className="inner text-start">
              <h3 className="title theme-gradient display-two">{t("525")}</h3>
              <div className="main-content">
                <div
                  className="understand-area"
                  style={{ margin: "20px 0", textAlign: "left" }}
                >
                  <WeUnderstandYou />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="understand-area">
          <div className="container">
            <div className="content">
              <h2 className="title" style={{ margin: "20px 0" }}>
                {t("533")}
              </h2>
              <WhyChoose />
              <CaseSection />
            </div>
          </div>
        </div>

        <Separator />
        <Separator />
      </div>

      <FooterTwo />
      <Copyright />
    </>
  );
};

export default ExtensiveLandOldHouse;
