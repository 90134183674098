import React from "react";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import SEO from "../common/SEO";
import HeaderTwo from "../common/header/HeaderTwo";
import TabThree from "../elements/tab/TabThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import SlipFour from "../elements/split/SlipFour";
import SlpitOne from "../elements/split/SlpitOne";
import AdvancePricingOne from "../elements/advancePricing/AdvancePricingOne";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";
import Separator from "../elements/separator/Separator";
import PortfolioOne from "../elements/portfolio/PortfolioOne";

import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from "../data/blog/BlogList.json";
import HeaderOne from "../common/header/HeaderOne";
import TabOne from "../elements/tab/TabOne";
import { useTranslation } from "react-i18next";
import ContactUs from "../elements/contact/ContactUs";

var BlogListData = BlogClassicData.slice(0, 3);

const StaffRecruitment = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <SEO title="International Consulting" />
      <main className="page-wrapper">
        <HeaderOne btnStyle="round" HeaderSTyle="header-not-transparent" />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                <div className="inner text-start">
                  <h1 className="title theme-gradient display-two">
                    {t("168")}
                    <br /> {t("169")}
                  </h1>
                  <p className="description">{t("515")}</p>
                  {/* <div className="button-group"> */}
                  {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="#">New Customer <i className="icon"><FiArrowRight /></i></Link> */}
                  {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="#">{t("53")}<i className="icon"><FiArrowRight /></i></Link>
                                    </div> */}
                </div>
              </div>
              <div className="col-lg-4 order-1 order-lg-2">
                <div className="thumbnail">
                  <img src="./images/bg/加入我们.png" alt="Banner Images" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Elements Area  */}
        <div className="rwt-tab-area rn-section-gap">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle={t("171")}
                  title={t("170")}
                  description=""
                />
              </div>
            </div>
            <TabOne />
          </div>
        </div>
        {/* End Elements Area  */}

        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb--40">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    // subtitle = {t("141")}
                    // title = {t("142")}
                    description=""
                  />
                </div>
              </div>

              <ContactUs currentPageUrl={window.location.href} />
            </div>
          </div>
          {/* End Contact Area  */}
        </div>

        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};

export default StaffRecruitment;
