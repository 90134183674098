import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// 设置自定义图标
const customIcon = new L.Icon({
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  shadowSize: [41, 41]
});

const MapComponent = () => {
  const markers = [
    { position: [-34.878754, 138.5599929], name: "Croydon Park" },
    { position: [-34.9588844, 138.5515113], name: "North Plympton" },
    { position: [-34.846893, 138.6601647], name: "Gilles Plains" },
    { position: [-35.0314797, 138.5550134], name: "Darlington" },
    { position: [-34.9510465, 138.5729288], name: "Everard Park" },
    { position: [-34.8157411, 138.6676741], name: "Modbury Heights" },
    { position: [-34.8469816, 138.6079405], name: "Gepps Cross" },
    { position: [-34.8313643, 138.6232345], name: "Pooraka" },
    { position: [-34.8420514, 138.6685573], name: "Valley View" },
    { position: [-34.8557089, 138.6082478], name: "Clearview" },
    { position: [-34.8894497, 138.6346655], name: "Marden" },
    { position: [-34.8726987, 138.6994934], name: "Athelstone" },
    { position: [-34.9439666, 138.5701341], name: "Keswick" },
    { position: [-35.1299831, 138.4934472], name: "Christie Downs" },
    { position: [-34.8352732, 138.631482], name: "Ingle Farm" },
    { position: [-34.8367197, 138.6474922], name: "Valley View" },
    { position: [-34.9741099, 138.5817581], name: "Clarence Gardens" },
    { position: [-34.8678532, 138.6607986], name: "Paradise" },
  ];

  return (
    <div className="container">
      <MapContainer center={[-34.9285, 138.6007]} zoom={10} style={{ height: "600px", width: "100%" }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {markers.map((marker, idx) => (
          <Marker key={idx} position={marker.position} icon={customIcon}>
            <Popup>{marker.name}</Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default MapComponent;
