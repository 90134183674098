import React, { useState } from "react";
import { Link } from "react-router-dom";
import QRCode from "react-qr-code"; // Ensure you have this package installed
import { Button } from "antd"; // Replace with your actual button library import
import SectionTitle from "../sectionTitle/SectionTitle"; // Replace with your actual SectionTitle component import
import { useTranslation } from "react-i18next"; // Import useTranslation for internationalization

const CaseSection = () => {
  const { t } = useTranslation();
  const [showQRCode, setShowQRCode] = useState(false);

  const handleButtonClick = () => {
    setShowQRCode(!showQRCode);
  };

  return (
    <>
      <div className="case section">
        <section style={{ padding: "20px", textAlign: "center" }}>
          <h2 style={{ margin: "20px 0" }}>{t("537")}</h2>
          <p style={{ fontSize: "1.2em", margin: "10px 0" }}>{t("538")}</p>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
            }}
          >
            {[...Array(6)].map((_, index) => (
              <Link to={`#`} key={index}>
                <img
                  src={`/images/service/project ${index + 1}.jpg`} // Update the path to match your images
                  alt={`Project ${index + 1}`}
                  style={{
                    width: "150px",
                    height: "150px",
                    margin: "10px",
                    borderRadius: "8px",
                    cursor: "pointer",
                    objectFit: "cover",
                    transition: "transform 0.3s ease",
                  }}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.transform = "scale(1.05)")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.transform = "scale(1)")
                  }
                />
              </Link>
            ))}
          </div>
        </section>
      </div>

      <div className="rwt-contact-area rn-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mb--40">
              <SectionTitle
                textAlign="text-center"
                radiusRounded=""
                description=""
              />
            </div>
          </div>

          {/* Button to Toggle QR Code */}
          <section style={{ padding: "20px", textAlign: "center" }}>
            <h2>{t("534")}</h2>

            <Button
              className="btn-default btn-large"
              type="primary"
              onClick={handleButtonClick}
            >
              {showQRCode ? t("537") : t("535")} {/* Toggle button text */}
            </Button>

            {/* Conditionally Render QR Code */}
            {showQRCode && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <QRCode value="https://www.cyberate.investments/Contact" size={256} />
              </div>
            )}

            <p>{t("536")}</p>
          </section>
        </div>
      </div>
    </>
  );
};

export default CaseSection;
